//import { Test } from "../_Test/Test.js";
//const test = new Test();
//test.sayHello();

$(".comparison__table").not(":first").hide();
$(".comparison__nav .comparison__button").click(function() {
    $(".comparison__nav .comparison__button").removeClass("active").eq($(this).index()).addClass("active");
    $(".comparison__table").hide().eq($(this).index()).show()
}).eq(0).addClass("active");

$('.feedback__form-button').click(function() {
    $('.popup-question').fadeIn();
    $('body').addClass('no-scroll');
});

$('.popup__close').click(function(){
    $(this).closest('.popup').fadeOut();
    $('body').removeClass('no-scroll');
});

$(document).mouseup(function(e) {
	var callbackPopup = $(".popup__container")
	if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
		$(".popup").fadeOut()
		$("body").removeClass("no-scroll")
	}
})

$(document).ready(function() {
    $('a[href^="#"]').click(function () { 
        let elementClick = $(this).attr("href").replace(/#/, '');
        if (elementClick && $('a[name='+elementClick+']').length){
            let destination = $('a[name='+elementClick+']').offset().top;
            $('html, body').animate( { scrollTop: destination }, 800 );
        }
        return false;
    });

    $(".fund__diagram-sector--blue").hover(function(){
        $('.fund__diagram-footnote--blue').addClass("active")
    },function(){
        $('.fund__diagram-footnote--blue').removeClass("active")
    });

    $(".fund__diagram-sector--yellow").hover(function(){
        $('.fund__diagram-footnote--yellow').addClass("active")
    },function(){
        $('.fund__diagram-footnote--yellow').removeClass("active")
    });

    $('.btm-text_show-more').on('click', function(e){
        e.preventDefault();
        $(this).closest('.footer__bottom').find('.btm-text_more').show();
        $(this).hide();
        $(window).scrollTop($(document).height());
    });
});
